export default function useStatusAccentBackgroundColour(status: string): string {
    switch (status) {
        case 'draft':
        case 'pending':
        case 'null':
            return 'bg-pastel-gray';
        case 'warning':
            return 'bg-pastel-orange';
        case 'sent':
        case 'processed':
            return 'bg-pastel-blue';
        case 'accepted':
        case 'paid':
        case 'delivered':
        case 'success':
            return 'bg-pastel-green';
        case 'overdue':
        case 'rejected':
        case 'failed':
        case 'external-failure':
        case 'internal-failure':
        case 'error':
            return 'bg-pastel-red';
    }
}
